html {
    --header--height: 12rem;
    --header--font-size: 1.5rem;
    --header__findings-title--width: 24rem;

    --content__extras--width: 12rem;
    --content__confidence-indicator--width: 1.25rem;

    --dropzone--border-width: 0.08rem;
    --dropzone--border-radius: 0.5rem;
    --dropzone__loader--size: 3rem;
    --dropzone__loader--thickness: 0.25rem;

    --list-screen__uploadzone--height: 24rem;
    --list-screen__uploadzone--width: 48rem;

    /*prevent bar of white on over-scroll*/
    background-color: #000000;
}

/* Loading fonts */

@font-face {
    font-family: Inter;
    src: url('../node_modules/@annaliseai/anna-design-tokens/build/fonts/Inter-Thin.ttf') format('truetype');
    font-weight: 100;
}

@font-face {
    font-family: Inter;
    src: url('../node_modules/@annaliseai/anna-design-tokens/build/fonts/Inter-ExtraLight.ttf') format('truetype');
    font-weight: 200;
}

@font-face {
    font-family: Inter;
    src: url('../node_modules/@annaliseai/anna-design-tokens/build/fonts/Inter-Light.ttf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: Inter;
    src: url('../node_modules/@annaliseai/anna-design-tokens/build/fonts/Inter-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: Inter;
    src: url('../node_modules/@annaliseai/anna-design-tokens/build/fonts/Inter-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: Inter;
    src: url('../node_modules/@annaliseai/anna-design-tokens/build/fonts/Inter-SemiBold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: Inter;
    src: url('../node_modules/@annaliseai/anna-design-tokens/build/fonts/Inter-Bold.ttf') format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: Inter;
    src: url('../node_modules/@annaliseai/anna-design-tokens/build/fonts/Inter-ExtraBold.ttf') format('truetype');
    font-weight: 800;
}

@font-face {
    font-family: Inter;
    src: url('../node_modules/@annaliseai/anna-design-tokens/build/fonts/Inter-Black.ttf') format('truetype');
    font-weight: 900;
}

body {
    font-family: Inter;
    overflow-x: hidden;
}
